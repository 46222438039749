import React from 'react';
import LicenseModal from './licenseModal';
import PreEntryModal from './preEntryModal';
import StandardPlayerModal from './standardPlayerModal';


const PlayerModal = ({
  show,
  player,
  showFooter,
  status,
  tournament,
  activeCategory,
  currentPlayers,
  onSubmitPlayerModal,
  onHide
}) => {


  if (tournament.data.isPreEntry) {
    // we show the pre-entry modal
    return (
      <PreEntryModal
        show={show}
        onHide={() => onHide()}
        onSubmit={(player) => onSubmitPlayerModal(player)}
        tournament={tournament}
        activeCategory={activeCategory}
        showFooter={showFooter}
        player={player}
        status={status}
        currentPlayers={currentPlayers}
      />
    )
  } else if (tournament.data.requireDotCloud) {
    // we show the license modal
    return (
      <LicenseModal
        show={show}
        onHide={() => onHide()}
        onSubmit={(player) => onSubmitPlayerModal(player)}
        tournament={tournament}
        activeCategory={activeCategory}
        showFooter={showFooter}
        player={player}
        status={status}
      />
    )
  } else {
    // we show the basic player data modal
    return (
      <StandardPlayerModal
        show={show}
        onHide={() => onHide()}
        onSubmit={(player) => onSubmitPlayerModal(player)}
        player={player}
        showFooter={showFooter}
        status={status}
      />
    )
  }
}

export default PlayerModal;