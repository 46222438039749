import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchSingleTournmanet, saveEntry } from '../../utils/api/index';
import { displayFooter, displayHeader } from '../../utils/components/tournamentComponents';
import renderField from '../../utils/dynamic-form/formField';
import createUuid from '../../utils/guid/generateGuid';
import testGenderValid from '../../utils/helpers/testGenderValid';
import EntryConfirmModal from '../../modules/confirmTeamModal';
import PlayerListTable from '../../modules/playerListTable';
import { STATUS_SEARCH, STATUS_UPDATE } from '../../utils/constants';

const basicInformation = [
  { "_id": "teamName", "type": "text", "title": "Team Name", "required": true, ref: { current: { value: null } } },
  {
    "_id": "teamCategory", "type": "radio", "title": "Division", "required": true, ref: { current: { value: null } },
    "options": [
      {
        text: 'Snr',
        value: 'Snr'
      },
      {
        text: 'Women',
        value: 'Women'
      }
    ]
  },
  { "_id": "teamManager", "type": "text", "title": "Team Manager", "required": true, ref: { current: { value: null } } },
  { "_id": "teamManagerEmail", "type": "email", "title": "Manager Email", "required": true, ref: { current: { value: null } } }
]


const Enter = (props) => {
  const [tournament, updateTournament] = useState({ status: 0, data: {}, basicInformation: basicInformation });
  const [playerList, updatePlayerlist] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [playerModal, setPlayerModal] = useState({
    show: false,
    player: null,
    showFooter: true,
    status: STATUS_SEARCH
  });

  const [entry, updateEntry] = useState({ status: 0 });
  const [checkoutButton, setCheckoutButton] = useState(true)
  const [confirmTeamModal, setConfirmTeamModal] = useState({ show: false });
  const history = useHistory();

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    //test if redirect or direct
    let tournament = null;
    if (props.location && props.location.state && props.location.state.tournament) {
      tournament = props.location.state.tournament;
    } else {
      //need to load from DB
      const result = await fetchSingleTournmanet(props.match.params.id);
      if (result.data && result.status === 200) {
        tournament = result.data;
      } else {
        console.error('Error loading tournament');
      }
    }
    console.log(tournament);
    //setting the active categories
    basicInformation[1].options = tournament.activeCategories.map(category => {
      return {
        text: category.title,
        value: category.id
      }
    });


    setActiveCategory(tournament.activeCategories[0]);
    updateTournament({
      status: 1, data: tournament, basicInformation: basicInformation.map((info, i) => {
        const ref = React.createRef();
        return { ...info, ref }
      })
    });

  }

  const confirmCheckout = () => {
    console.log(entry);
    saveEntry(entry.data).then(_ => {
      console.log(_.data);

      history.push(`/entry/${tournament.data.uid}/checkout/${entry.data.id}/processed`, { tournament: tournament.data, entry: entry.data });
    })
    setCheckoutButton(false);
  }

  const confirmTeam = () => {
    const validPlayers = playerList.filter(player => {
      player.ageValidity = player.age >= activeCategory.minAge && player.age <= activeCategory.maxAge;
      player.genderValid = testGenderValid(activeCategory, player);
      return player.ageValidity && player.genderValid
    });
    const teamName = tournament.basicInformation[0].ref.current?.value;
    const manager = tournament.basicInformation[2].ref.current?.value;
    const email = tournament.basicInformation[3].ref.current?.value;
    updateEntry({
      data: {
        tournamentUid: tournament.data.uid,
        additionalCosts: tournament.data.additionalCosts,
        id: createUuid(),
        activeCategory,
        validPlayers,
        teamName,
        manager,
        email
      }
    });
  }

  if (tournament.status === 0) {
    return <Spinner animation="border" variant="info"></Spinner>
  } else {
    return (
      <React.Fragment>
        {displayHeader(tournament)}
        <Card>
          <Form onSubmit={(e) => {
            e.preventDefault();
            confirmTeam();
            setConfirmTeamModal({ show: true });
            // confirmTeam() 
          }}>
            <Card.Header>
              <Card.Title>Team Information</Card.Title>
              <Card.Subtitle>Enter the info of the team you are entering</Card.Subtitle>
            </Card.Header>
            <Card.Body>
              {
                tournament.basicInformation.map((fieldData, i) => {
                  if (i === 1) {
                    // here we add a little addition to the options to include the default for the first one
                    fieldData.options[0].isDefault = true;
                    console.debug(fieldData);
                    return <React.Fragment key={fieldData._id}>{
                      renderField(fieldData, fieldData.ref, (idNumber) => {
                        setActiveCategory(tournament.data.activeCategories.find(category => category.id === idNumber))
                      })}</React.Fragment>
                  }
                  return <React.Fragment key={fieldData._id}>{renderField(fieldData, fieldData.ref)}</React.Fragment>
                })
              }
              <br />
              <hr />
              <br />
              <PlayerListTable
                playerList={playerList}
                tournament={tournament}
                activeCategory={activeCategory}
                setPlayerList={(players) => updatePlayerlist(players)}

              />
            </Card.Body>
            <Card.Footer>
              {/* {playerList.length >= tournament.data.minPlayers ? */}
              <Button type="submit">Confirm Team</Button>
              <React.Fragment></React.Fragment>
              {/* } */}
            </Card.Footer>
          </Form>
        </Card>

        {displayFooter(tournament)}
        <EntryConfirmModal
          activeCategory={activeCategory}
          show={confirmTeamModal.show}
          onHide={() => { setConfirmTeamModal({ show: false }) }}
          onSubmit={() => { confirmCheckout() }}
          tournament={tournament}
          entry={entry}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(Enter);