import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import renderField from '../../utils/dynamic-form/formField';
import { fetchSingleTournmanet, fetchEntry, saveEntry, fetchInvoice } from '../../utils/api/index';
import PaynowButton from '../../utils/payfast/PaynowButton';

import { displayHeader, displayFooter } from '../../utils/components/tournamentComponents'

const payeeForm = [
    { "_id": "firstName", "type": "text", "title": "Payee First Name", "required": true, ref: { current: { value: null } } },
    { "_id": "lastName", "type": "text", "title": "Payee Last Name", "required": true, ref: { current: { value: null } } },
    { "_id": "email", "type": "email", "title": "Payee Email", "required": true, ref: { current: { value: null } } }
]


const Invoice = (props) => {
    const [tournament, updateTournament] = useState({ status: 0, data: {} });
    const [entry, updateEntry] = useState({ status: 0, data: {} });
    const [payeeDetails, setPayeeDetails] = useState({ status: 0, form: payeeForm })
    const [invoice, updateInvoice] = useState({ status: 0, data: {} });
    const [paymentOption, setPaymentOption] = useState('payfast');
    const history = useHistory();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        //test if redirect or direct
        console.log('...', 'in init');
        let tournament = null;
        let entry = null;
        let entryStatus = 0;
        let invoice = null;
        let invoiceStatus = 0;

        if (props.location && props.location.state && props.location.state.tournament) {
            tournament = props.location.state.tournament;
        } else {
            //need to load from DB
            const result = await fetchSingleTournmanet(props.match.params.id);
            if (result.data && result.status === 200) {
                tournament = result.data;
            } else {
                console.log('fuck...')
            }
        }

        if (props.location && props.location.state && props.location.state.entry) {
            entry = props.location.state.entry;
        } else {
            //need to load from DB
            const result = await fetchEntry(props.match.params.entryId);
            if (result.data && result.status === 200) {
                entry = result.data;
                entryStatus = 1
            } else {
                entryStatus = -1
            }
        }

        // we always fetch the latest invoice from the DB
        const result = await fetchInvoice(props.match.params.entryId, props.match.params.invoiceId);
        if (result.data && result.status === 200) {
          invoice = result.data;
          invoiceStatus = 1
        } else {
          invoiceStatus = -1
        }
        updateTournament({ status: 1, data: tournament });
        updateEntry({ status: entryStatus, data: entry });
        updateInvoice({ status: invoiceStatus, data: invoice });
        setPayeeDetails({
            ...payeeDetails, status: 1, form: payeeForm.map(field => {
                return {
                    ...field,
                    ref: React.createRef()
                }
            })
        });
    }
    const loadPayout = () => {
        const payee = payeeDetails.form.reduce((payee, field) => {
            payee[field._id] = field.ref.current.value;
            return payee;
        }, {});
        const updatedEntry = {
            ...entry.data,
            paymentStatus: 'pending',
            payee
        }
        saveEntry(updatedEntry);
        updateEntry({...entry, data:updatedEntry})
        setPayeeDetails({ ...payeeDetails, status: 2, payee });
    }
    const displayPayeeForm = () => {
        switch (payeeDetails.status) {
            case 1: {
                return <React.Fragment>
                    <p>Please enter the details for the Payee</p>
                    <Form onSubmit={(e) => { e.preventDefault(); loadPayout(); }}>
                        <div className="row mb-4">
                            {
                                payeeDetails.form.map((field, i) => {
                                    return (
                                        <div className={`col${i <= 1 ? '-6' : ''}`}>
                                            {renderField(field, field.ref)}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Button type="submit">Load Checkout Link</Button>
                    </Form>
                </React.Fragment>
            }
            case 2: {
                return (
                    <React.Fragment>
                        <p>You will be redirected to PayFast to process the payment</p>
                        {
                            PaynowButton({
                                firstName: payeeDetails.payee.firstName,
                                lastName: payeeDetails.payee.lastName,
                                amount: entry.data.activeCategory.cost,
                                email: payeeDetails.payee.email,
                                id: entry.data.id,
                                teamName: entry.data.teamName,
                                tournament: tournament.data.title,
                                tournamentId: tournament.data.uid
                            })
                        }
                    </React.Fragment>
                )
            }
            default: return <React.Fragment></React.Fragment>
        }
    }

    const navigateTo = (location) => {
        switch (location) {
            case 'enter':
                history.push(`entry/${tournament.data.uid}`, { tournament: tournament.data });
                break;
            default:
                history.push(`entry`);
                break;
        }
    }

    // renders the payment options as a bootstrap card with radio buttons to choose option
    // options are payfast or manual EFT
    const displayPaymentOptions = () => {
        return (
            <Card>
                <Card.Header>
                    <Card.Title>Payment Options</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="PayFast" 
                                name="paymentOption"
                                id="payfast"
                                checked={paymentOption === 'payfast'}
                                onChange={(e) => { setPaymentOption(e.target.id) }}
                            />
                            <Form.Check
                                type="radio"
                                label="Manual EFT"
                                name="paymentOption"
                                id="eft"
                                checked={paymentOption === 'eft'}
                                onChange={(e) => { setPaymentOption(e.target.id) }}
                            />
                        </Form.Group>
                    </Form>
                    <Card>
                      <Card.Body hidden={paymentOption !== 'eft'}>
                        <p>
                        Please transfer the amount to the following account details.
                        </p>
                        <p>
                          Proof of payment must be sent to <a href={`mailto:${tournament.data.organiserEmail}`} target="_blank" rel="noreferrer">{tournament.data.organiserEmail}</a> 
                          to confirm your entry.
                          </p>
                        <i>Note: Your entry will only be valid on proof of payment received</i>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Account Name</td>
                              <td>{tournament.data.bankDetails.accountName}</td>
                            </tr>
                            <tr>
                              <td>Account Number</td>
                              <td>{tournament.data.bankDetails.accNum}</td>
                            </tr>
                            <tr>
                              <td>Bank</td>
                              <td>{tournament.data.bankDetails.bank}</td>
                            </tr>
                            <tr>
                              <td>Branch Code</td>
                              <td>{tournament.data.bankDetails.branch}</td>
                            </tr>
                            <tr>
                              <td>Reference</td>
                              <td>{tournament.data.bankDetails.ref}</td>
                            </tr>
                            <tr>
                              <td>Amount</td>
                              <td>R {getTotalAmount()}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                      <Card.Body hidden={paymentOption !== 'payfast'}>
                        {displayPayeeForm()}
                      </Card.Body>
                    </Card>
                </Card.Body>
            </Card>
        )
    }

    const getTotalAmount = () => {
      // reduce the feeItems to a total amount
      return invoice.data.feeItems.reduce((total, item) => {
        return total + item.amount;
      }, 0);
    }

    if (tournament.status === 0) {
        return <Spinner animation="border" color="info"></Spinner>
    } else if (entry.status === 1 && invoice.status === 1) {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <Card.Title>Invoice</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <h3>Team Information</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Team name</td>
                                    <td>{entry.data.teamName}</td>
                                </tr>
                                <tr>
                                    <td>Team category</td>
                                    <td>{entry.data.activeCategory.title}</td>
                                </tr>
                                <tr>
                                    <td>Player Name</td>
                                    <td>{invoice.data.player.name}</td>
                                </tr>
                                <tr>
                                    <td>Player CSA Number</td>
                                    <td>{invoice.data.player.csaNumber}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h3>Cost Table</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                              {invoice.data.feeItems.map((item, i) => {
                                      return (
                                          <tr key={i}>
                                              <td>{item.name}</td>
                                              <td>{item.amount}</td>
                                          </tr>
                                      );
                                  })}   
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th style={{ textAlign: "right" }}>TOTAL</th>
                                    <th>{getTotalAmount()}</th>
                                </tr>
                            </tfoot>
                        </Table>
                        <h3>Payment Options</h3>
                        {displayPaymentOptions()}
                    </Card.Body>
                    <Card.Footer>
                        {displayPayeeForm()}
                    </Card.Footer>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <h2>This Invoice does not exist</h2>
                    </Card.Header>
                    <Card.Body>
                        <Button onClick={() => { navigateTo('selectEvent') }}>Select Events</Button>
                        <Button onClick={() => { navigateTo('enter') }}>Enter</Button>
                    </Card.Body>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    }
}


export default withRouter(Invoice);