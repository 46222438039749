import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

class CustomSpinner extends React.Component {

    render() {
        return (<div className="w-100 h-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner animation="border" varient="info"></Spinner>
        </div>)
    }
}

export default CustomSpinner;