import React from 'react';
import Table from 'react-bootstrap/Table';

const EnteredTeamDiv = (props) => {
  const { entry } = props;
  return (
    <React.Fragment>
      <h3>Team Information</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Team name</td>
            <td>{entry.data.teamName}</td>
          </tr>
          <tr>
            <td>Team category</td>
            <td>{entry.data.activeCategory.title}</td>
          </tr>
          <tr>
            <td>Team manager</td>
            <td>{entry.data.manager}</td>
          </tr>
          <tr>
            <td>Team manager email</td>
            <td>{entry.data.email}</td>
          </tr>
        </tbody>
      </Table>
      <h3>Players</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="row">Number</th>
            <th>Name</th>
            <th>CSA number</th>
          </tr>
        </thead>
        <tbody>
          {entry.data.validPlayers.map((player, i) => {
            return (
              <tr key={player.csaId}>
                <td>{player.number}</td>
                <td>{player.name}</td>
                <td>{player.csaId}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default EnteredTeamDiv;