import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import { Button, Card, Table } from 'react-bootstrap';
import { fetchSingleTournmanet, fetchAllEntries } from '../../utils/api/index';

import { displayHeader, displayFooter } from '../../utils/components/tournamentComponents'


const EventInfo = (props) => {
  const [tournament, updateTournament] = useState({ status: 0, data: {} });
  const [entryList, updateEntryList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    init();
  }, [])

  const init = async () => {

    const [tournament, entrys] = await Promise.all([
      new Promise((resolve) => {
        fetchSingleTournmanet(props.match.params.id).then(result => {
          if (result.data && result.status === 200) {
            resolve(result.data);
          } else {
            resolve(null);
          }
        })
      }),
      new Promise((resolve) => {
        fetchAllEntries(props.match.params.id).then(result => {
          if (result.data && result.status === 200) {
            resolve(result.data);
          } else {
            resolve(null);
          }
        })
      })
    ])
    entrys.sort((a, b) => a.activeCategory.id > b.activeCategory.id ? 1 : -1)
    updateTournament({ status: 1, data: tournament });
    updateEntryList(entrys);
    const teamOutput = entrys.map(entry => {
      let team = {
        teamName: entry.teamName,
        category: entry.activeCategory.id
      }
      entry.validPlayers.forEach((player, i) => {
        team[`player_${i}`] = `${player.number} ${player.name}`
      })
      return team;
    })
    console.log(JSON.stringify(teamOutput));
  }

  if (tournament.status === 0) {
    return <Spinner></Spinner>
  } else {
    return (
      <div className="h-100 w-100">
        {displayHeader(tournament)}
        <Card>
          <Card.Header>
            <Card.Title>Entry List Confimation</Card.Title>
          </Card.Header>
          <Card.Body>
            <h3>Teams Information</h3>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Team Name</th>
                  <th>Division</th>
                  <th>Entry Form</th>
                  <th>Players</th>
                </tr>
              </thead>
              <tbody>
                {entryList.map(entry => {
                  return (
                    <tr>
                      <td>{entry.teamName}</td>
                      <td>{entry.activeCategory.title}</td>
                      <td>
                        <div>
                          <Button variant="primary" onClick={() => {
                            history.push(`/entry/${tournament.data.uid}/checkout/${entry.id}/processed`);
                          }}>
                            View Entry Form
                          </Button>
                        </div>
                      </td>
                      <td>
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              entry.validPlayers.map(player => {
                                return (
                                  <tr>
                                    <td>{player.number}</td>
                                    <td>{player.name}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>

                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

          </Card.Body>
        </Card>
        {displayFooter(tournament)}
      </div>
    )
  }
}


export default withRouter(EventInfo);