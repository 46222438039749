import axios from 'axios';
//local instance http://localhost:5001/canoe-polo-live/us-central1/
// const localInstance = 'http://localhost:5000/canoe-polo-live/us-central1/api'
const localInstance = 'https://us-central1-canoe-polo-live.cloudfunctions.net/api'
const publicInstance = 'https://us-central1-canoe-polo-live.cloudfunctions.net/api'
const instance = axios.create({
  baseURL: window.location.hostname === 'localhost' ? localInstance : publicInstance,
  headers: {
    "Content-Type": "application/json",
  },
});


export function fetchTournaments () {
  return instance.get('/tournament/open')
}
export function fetchSingleTournmanet (tournamentId) {
  return instance.get(`/tournament/${tournamentId}`)
}
export function fetchCsaLicense (csaNumber) {
  return instance.post('/license/csa', { csaId: csaNumber })
}
export function fetchPreEnteredPlayer (tournamentId, csaNumber) {
  return instance.get(`/tournament/${tournamentId}/pre-entered/${csaNumber}`)
}
export function fetchEntry (entryId) {
  return instance.get(`/entry/${entryId}`);
}
export function fetchAllEntries (tournamentId) {
  return instance.get(`/entry/all/${tournamentId}`)
}
export function saveEntry (entry) {
  return instance.post(`/entry/${entry.id}`, entry)
}
export function savePreEntry (entry) {
  return instance.post(`/entry/pre-entered/${entry.id}`, entry)
}
export function fetchInvoice (entryId, invoiceId) {
  return instance.get(`/entry/${entryId}/invoice/${invoiceId}`)
}

