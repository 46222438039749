import { Switch, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import Container from 'react-bootstrap/Container';

import Landing from "./pages/landing";
import SelectEvent from "./pages/entry/select-event";
import Enter from './pages/entry/enter';
import Checkout from './pages/entry/checkout';
import PaymentStatus from './pages/entry/payment-results';
import TeamList from './pages/entry/teamlist';
import Invoice from './pages/entry/invoice';
import PreEntry from './pages/entry/pre-entry';
import ProcessedEntry from "./pages/entry/processed-entry";

function App() {
  return (
    <Container className="h-100" style={{ margin: "auto", display: "flex", justifyContent: "center", alignItems: "baseline" }}>
      <div className="h-100 w-100">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/entry" component={SelectEvent} />
            <Route exact path="/pre-entry/:id" component={PreEntry} />
            <Route exact path="/entry/:id/processed/:entryId" component={ProcessedEntry} />
            <Route exact path="/entry/:id" component={Enter} />
            <Route exact path="/entry/:id/invoice/:entryId/:invoiceId" component={Invoice} />
            <Route exact path="/entry/:id/checkout/:entryId" component={Checkout} />
            <Route exact path="/entry/:id/checkout/:entryId/processed" component={PaymentStatus} />
            <Route exact path="/teamlist/:id" component={TeamList} />
          </Switch>
        </BrowserRouter>
      </div>
    </Container>
  );
}

export default App;
