/**
 * A modal that receives the tournament information on which license is required and
 * checks the player by using CSA number.
 * 
 * The player is returned to the entry module via callback.
 */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Table, Alert, Card } from 'react-bootstrap';
import { fetchPreEnteredPlayer } from '../utils/api/index';
import renderField from '../utils/dynamic-form/formField';
import Spinner from '../utils/components/Spinner';
import testAgeValid from '../utils/helpers/testAgeValid';
import testGenderValid from '../utils/helpers/testGenderValid';

export const STATUS_LOADING = 0;
export const STATUS_SEARCH = 1;
export const STATUS_VALID = 2;
export const STATUS_INVALID = 3;
export const STATUS_UPDATE = 4;
export const STATUS_NOT_FOUND = 5;
export const STATUS_VALID_PRE_ENTERED = 6;


const addPlayerField = { "_id": "csaNumber", "type": "number", "title": "CSA Number", "required": true }
const addPlayerPlayerNumber = { "_id": "playerNumber", "type": "number", "title": "Number", "required": true }

/** props must be tournament, show, player, onSubmitPlayerModal */
const PreEntryModal = (props) => {

  const [tournament, setTournament] = useState(props.tournament);
  const [activeCategory, setActiveCategory] = useState(props.activeCategory);
  const [currentPlayers, setCurrentPlayers] = useState(props.currentPlayers);
  // default to true if showFooter is not defined
  const [showFooter, setShowFooter] = useState(props.showFooter === undefined ? true : props.showFooter);
  const [player, setPlayer] = useState(props.player);

  const [error, setError] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [modalDisplay, setModalDisplay] = useState(<React.Fragment></React.Fragment>);
  const [status, setStatus] = useState(props.status);

  const playerSearchRef = useRef(null);
  const playerNumberRef = useRef(null);

  function displayPlayerDetails () {
    const member = player;
    if(!member){
      return (<React.Fragment></React.Fragment>);
    }
    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{member.name}</td>
          </tr>
          <tr>
            <td>Age</td>
            <td>{member.age}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>{member.gender}</td>
          </tr>
          <tr>
            <td>Age Validity</td>
            <td>{member.ageValidity ? 'VALID' : 'INVALID'}</td>
          </tr>
          <tr>
            <td>Gender Validity</td>
            <td>{member.genderValid ? 'VALID' : 'INVALID'}</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const submitPlayer = () => {
    //fetch the player number and add it to the player object
    player.number = playerNumberRef.current.value;

    if (player.number === "") {
      setUpdateError("Please enter a player number and email");
    } else {
      props.onSubmit(player);
      setPlayer({});
      setStatus(STATUS_SEARCH);
    }
  }


  const searchForPlayer = () => {
    setStatus(STATUS_LOADING);
    // we check if the tournament has the isPreEntry flag set to true
    // if it is, we will not check the player's license
    const csaId = playerSearchRef.current.value;
    //check if the player list already has the player
    const playerAlreadyEntered = currentPlayers.find(player => player.csaId === csaId);
    if (playerAlreadyEntered) {
      setError('Player already entered in your team!');
      setStatus(STATUS_INVALID);
      setShowFooter(false);
      return;
    }
    fetchPreEnteredPlayer(tournament.data.uid, playerSearchRef.current.value).then((result) => {
      console.debug(result.data);
      loadPreEntryPlayerData(result.data);
    }).catch(error => {
      console.error(error);
      loadPreEntryPlayerData({});
    })

  }

  const loadPreEntryPlayerData = (memberData) => {
    if (memberData.memberFound) {
      const { ageValidity, age, dateofBirth } = testAgeValid(memberData, activeCategory, tournament.data);
      const genderValid = testGenderValid(activeCategory, memberData);
      const testedMember = {...memberData, ageValidity, age, dateofBirth, genderValid}
      setPlayer(testedMember);
      if (testedMember.ageValidity && testedMember.genderValid) {
        setError('');
        setStatus(STATUS_VALID);
        setShowFooter(true);
      } else {
        // now we check if its the age, license or gender that is invalid and set the error message
        if (!testedMember.ageValidity) {
          setError(`Player's age is invalid for this category`);
        } else if (!testedMember.genderValid) {
          setError('Player is incorrect gender for this category');
        }
        setStatus(STATUS_INVALID);
        setShowFooter(false);
      }
    } else {
      setStatus(STATUS_NOT_FOUND);
      setShowFooter(false);
    }
  }

  /** this use effect is to make sure the status and player is reset */
  useEffect(() => {
    setStatus(props.status);
    setPlayer(props.player);
    setActiveCategory(props.activeCategory);
    setTournament(props.tournament);
    setError('');
    setUpdateError('');
    setCurrentPlayers(props.currentPlayers);
  }, [props.status])

  useEffect(() => {
    switch (status) {
      case STATUS_SEARCH:
        setModalDisplay(
          <React.Fragment>
            {renderField(addPlayerField, playerSearchRef)}
            <Card.Footer>
              <Button onClick={() => searchForPlayer()}>Search for Player</Button>
            </Card.Footer>
          </React.Fragment>
        )
        break;
      case STATUS_VALID:
        setModalDisplay(
          <React.Fragment>
            <span>Player Details</span>
            {displayPlayerDetails()}
            <br />
            <p>Player is valid, please enter their playing number below</p>
            {renderField(addPlayerPlayerNumber, playerNumberRef)}
          </React.Fragment>
        )
        break;
      case STATUS_INVALID:
        setModalDisplay(
          <React.Fragment>
            <span>Player Details</span>
            {displayPlayerDetails()}
            <br />
            <Alert variant={'danger'}>{error}</Alert>
            <Card.Footer>
              <Button onClick={() => { setStatus(STATUS_SEARCH) }}>Search Again</Button>
            </Card.Footer>
          </React.Fragment>
        )
        break;
      case STATUS_UPDATE:
        setModalDisplay(
          <React.Fragment>
            <span>Player Number Update</span>
            {renderField({
              ...addPlayerPlayerNumber,
              value: player.number
            }, playerNumberRef)}
          </React.Fragment>
        )
        break;
      case STATUS_NOT_FOUND:
        setModalDisplay(
          <React.Fragment>
            <Alert variant={'danger'}>Player does not exist. Please search again</Alert>
            {renderField(addPlayerField, playerSearchRef)}
            <Button onClick={() => searchForPlayer()}>Search for Player</Button>
          </React.Fragment>
        )
        break;
      default:
        setModalDisplay(<Spinner animation="border" color="info"></Spinner>)

    }
  }, [status]);



  return (
    <Modal animation={true} centered={true} show={props.show} onHide={() => props.onHide()} className='p-2'>
      <Modal.Title className='p-2'>Player Actions</Modal.Title>
      <Modal.Body>
        {
          modalDisplay
        }
        {updateError !== '' ? <Alert variant={'danger'}>{updateError}</Alert> : <></>}
      </Modal.Body>
      <Modal.Footer className={showFooter ? '' : 'd-none'}>
        <Button onClick={() => submitPlayer()} type={'info'}>Submit</Button>
        <Button onClick={() => props.onHide()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PreEntryModal;