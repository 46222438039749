import React, { useState, useEffect } from 'react';
import Spinner from '../../utils/components/Spinner';
import { Card, Button } from 'react-bootstrap'
import { fetchTournaments, fetchCsaLicense } from '../../utils/api/index';
import { useHistory, withRouter } from "react-router-dom";
import { fromUnixTime, format } from 'date-fns';

function displayFormattedDate (date) {
  const dateObj = fromUnixTime(date['_seconds']);
  return format(dateObj, 'dd MMM yyyy');
}

const SelectEvent = (props) => {
  const history = useHistory();
  //setState stuff for entry id, team data, playerId
  const [tournamentList, updateTournamentList] = useState({ status: 0, data: [] });
  const [dotCloudConnection, setDotCloud] = useState({ active: false });
  /**
   * @param {String} tournmentUid 
   */
  const openEntry = (tournamentUid, index) => {
    const tournament = tournamentList.data[index];
    if (tournament.isPreEntry) {
      history.push(`pre-entry/${tournamentUid}`);
    } else {
      history.push(`entry/${tournamentUid}`, { tournament: tournamentList.data[index] });
    }
  }
  // //
  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    const [connection, tournament] = await Promise.all([
      fetchCsaLicense('7858'),
      fetchTournaments()
    ]);
    //checking api status with Dot Cloud
    if (connection.data && connection.data.ok) {
      setDotCloud({ active: connection.data.ok })
    } else {
      setDotCloud({ active: false })
    }

    updateTournamentList({ status: 1, data: tournament.data })
  }

  const testIfTournamentEntryIsValid = (tournament) => {
    if (!tournament.requireDotCloud) {
      return true;
    }
    if (dotCloudConnection.active) {
      return true;
    }
    return false;
  }

  if (tournamentList.status === 0) {
    return <Spinner animation="border" color="red"></Spinner>
  } else {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Select Event</Card.Title>
          <Card.Subtitle>Select the event you are entering for</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {
            tournamentList.data.map((tournament, i) => {
              return (
                <div className='row shadow mb-4 bg-light-grey' key={i}>
                  <div className='col-md-3 col-sm-12'><img src={tournament.logoUrl} className='img-thumbnail' alt='Logo' /></div>
                  <div className='col-md-9 col-sm-12 row'>
                    <div className='col-12'>{tournament.title} </div>
                    <div className='col-12'>{`From ${displayFormattedDate(tournament.startDate)} to ${displayFormattedDate(tournament.endDate)}`}</div>
                    <div className='col-12'>{`Location: ${tournament.location}`}</div>
                    <div className='col-12'>{tournament.description}</div>
                    <div className='col-12'>Requires CSA License: {tournament.requireDotCloud ? 'Yes' : 'No'}</div>
                    <div className='col-12'>Is pre-entry on Paddler Portal: {tournament.isPreEntry ? 'Yes' : 'No'}</div>
                    <div className='col-12 align-content-right mt-3'>
                      <hr />
                      {
                        testIfTournamentEntryIsValid(tournament) ?
                          <button className='btn btn-success mx-2' onClick={() => openEntry(tournament.uid, i)}>Enter</button> :
                          <React.Fragment>
                            <h5>Connection to Paddler Portal is down</h5>
                            <p>This entry requires the CSA paddler portal. Once this connection is back up, entries will be permitted</p>
                          </React.Fragment>
                      }
                      <Button variant="primary" onClick={() =>
                        history.push(`teamlist/${tournament.uid}`, { tournament: tournamentList.data[i] })
                      }>View Entries</Button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Card.Body>
      </Card>
    )
  }
}

export default withRouter(SelectEvent);