import React, { useState } from 'react';
import testGenderValid from '../utils/helpers/testGenderValid';
import { Table, Button, ButtonGroup } from 'react-bootstrap';
// import LicenseModal, { STATUS_SEARCH, STATUS_UPDATE } from '../../modules/licenseModal';
import PlayerModal from './playerModal';
import { STATUS_SEARCH, STATUS_UPDATE } from '../utils/constants';

const PlayerListTable = ({ playerList, tournament, activeCategory, setPlayerList }) => {

  const [playerModal, setPlayerModal] = useState({
    show: false,
    player: null,
    showFooter: true,
    status: STATUS_SEARCH
  });


  const deletePlayerFromList = (index) => {
    let newPlayerList = null;
    if (playerList.length === 1) {
      newPlayerList = [];
    } else {
      newPlayerList = playerList.filter((_, i) => i !== index);
    }
    setPlayerList(newPlayerList);
  }

  return (
    <React.Fragment>
      <h4> Players </h4>
      <p>Currently have {playerList.length} players. Tournament requires a minimum of <b>{tournament.data.minPlayers}</b> players</p>
      <Table striped bordered hover>
        <thead className="thead-light">
          <tr>
            <th scope="row">#</th>
            <th>CSA #</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {
            playerList.map((player, i) => {
              player.ageValidity = player.age >= activeCategory.minAge && player.age <= activeCategory.maxAge;
              player.genderValid = testGenderValid(activeCategory, player);
              return (
                <tr key={player.csaId} className={!player.ageValidity || !player.genderValid ? 'bg-danger' : ''}>
                  <td>{player.number}</td>
                  <td>{player.csaId}</td>
                  <td>{player.name}</td>
                  <td>{player.gender}</td>
                  <td>
                    <ButtonGroup>
                      <Button onClick={() => { deletePlayerFromList(i) }}>Delete</Button>
                      <Button onClick={() => {
                        setPlayerModal({ show: true, showFooter: true, status: STATUS_UPDATE, player: player })
                      }}>Edit</Button>
                    </ButtonGroup>
                    {
                      !player.ageValidity ? (<p><br /><b>Player's age is not valid and will be ignored in the entry</b></p>) :
                        <React.Fragment>{
                          !player.genderValid ? (<p><br /><b>Player's gender is not valid and will be ignored in the entry</b></p>) :
                            <React.Fragment></React.Fragment>
                        }</React.Fragment>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}><Button onClick={() => {
              setPlayerModal({ show: true, showFooter: false, status: STATUS_SEARCH, player: null })
            }}>Add Player</Button></td>
          </tr>
        </tfoot>
      </Table>
      <PlayerModal
        show={playerModal.show}
        onHide={() => setPlayerModal({ show: false })}
        onSubmitPlayerModal={(player) => {
          if (playerModal.status === STATUS_UPDATE) {
            const newPlayerList = playerList.map(p => {
              if (p.uid === player.uid) {
                return player;
              } else {
                return p;
              }
            });
            setPlayerList(newPlayerList);
          } else {
            setPlayerList([...playerList, player]);
          }
          setPlayerModal({ show: false });
        }}
        tournament={tournament}
        activeCategory={activeCategory}
        showFooter={playerModal.showFooter}
        player={playerModal.player}
        status={playerModal.status}
      />
    </React.Fragment>
  );
}

export default PlayerListTable;