
export default function testGenderValid (activeCategory, member) {
  if (!activeCategory.gender) {
    return true;
  }
  if (member.gender.toLowerCase() === activeCategory.gender.toLowerCase()) {
    return true;
  }
  if (activeCategory.gender.toLowerCase() === 'any') {
    return true;
  }
  return false;
}