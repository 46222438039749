import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { fetchSingleTournmanet, fetchEntry } from '../../utils/api/index';

import { displayHeader, displayFooter } from '../../utils/components/tournamentComponents'


const PaymentProcessed = (props) => {
  const [tournament, updateTournament] = useState({ status: 0, data: {} });
  const [entry, updateEntry] = useState({ status: 0, data: {} });
  const history = useHistory();

  useEffect(() => {
    init();
  }, [])

  const init = async () => {

    const [tournament, entry] = await Promise.all([
      new Promise((resolve) => {
        fetchSingleTournmanet(props.match.params.id).then(result => {
          if (result.data && result.status === 200) {
            resolve(result.data);
          } else {
            resolve(null);
          }
        })
      }),
      new Promise((resolve) => {
        fetchEntry(props.match.params.entryId).then(result => {
          if (result.data && result.status === 200) {
            resolve(result.data);
          } else {
            resolve(null);
          }
        })
      })
    ])
    updateTournament({ status: 1, data: tournament });
    updateEntry({ status: entry ? 1 : 0, data: entry });
  }

  const navigateTo = (location) => {
    switch (location) {
      case 'enter':
        history.push(`entry/${tournament.data.uid}`, { tournament: tournament.data });
        break;
      default:
        history.push(`entry`);
        break;
    }
  }

  const sumCosts = () => {
    let sumTotal = 0;
    const total = entry.data.validPlayers.length * entry.data.activeCategory.cost;
    const items = [];
    if (tournament.data.additionalCosts) {
      const foundItems = tournament.data.additionalCosts.map(cost => {
        if (cost.type === 'percentage') {
          const itemCost = total * cost.amount
          sumTotal += itemCost
          return { name: cost.name, total: itemCost }
        } else {
          sumTotal += cost.amount
          return { name: cost.name, total: cost.amount }
        }
      })
      items.push({
        name: 'Team entry',
        total: total - sumTotal
      })
      items.push(...foundItems)
    } else {
      items.push({
        name: 'Team entry',
        total: total
      })
    }
    return items;
  }

  const getCosts = () => {
    const items = sumCosts();

    return items.map(item => {
      return (<tr>
        <td>{item.name}</td>
        <td>{item.total}</td>
      </tr>)
    })
  }

  if (tournament.status === 0) {
    return <Spinner animation="border" color="info"></Spinner>
  } else if (entry.status === 1) {
    return (
      <React.Fragment>
        {displayHeader(tournament)}
        <Card>
          <Card.Header>
            <Card.Title>Entry Confimation</Card.Title>
          </Card.Header>
          <Card.Body>
            <h3>Team Information</h3>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Team name</td>
                  <td>{entry.data.teamName}</td>
                </tr>
                <tr>
                  <td>Team category</td>
                  <td>{entry.data.activeCategory.title}</td>
                </tr>
                <tr>
                  <td>Team manager</td>
                  <td>{entry.data.manager}</td>
                </tr>
                <tr>
                  <td>Team manager email</td>
                  <td>{entry.data.email}</td>
                </tr>
              </tbody>
            </Table>
            <h3>Players</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th scope="row">Number</th>
                  <th>Name</th>
                  <th>CSA number</th>
                </tr>
              </thead>
              <tbody>
                {entry.data.validPlayers.map((player, i) => {
                  return (
                    <tr key={player.csaId}>
                      <td>{player.number}</td>
                      <td>{player.name}</td>
                      <td>{player.csaId}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <h3>Costs</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {getCosts()}
              </tbody>
              <tfoot>
                <tr>
                  <th style={{ textAlign: "right" }}>TOTAL</th>
                  <th>{
                    sumCosts().reduce((sum, item) => {
                      return sum + item.total
                    }, 0)}</th>
                </tr>
              </tfoot>
            </Table>
            <br />
            <div>
              <h3>Bank Details</h3>
              <Table striped bordered hover className="w-50">
                <tbody>
                  <tr><td>Account Name</td><td>{tournament.data.bankDetails.accountName}</td></tr>
                  <tr><td>Account Number</td><td>{tournament.data.bankDetails.accNum}</td></tr>
                  <tr><td>Bank Name</td><td>{tournament.data.bankDetails.name}</td></tr>
                  <tr><td>Branch</td><td>{tournament.data.bankDetails.branch}</td></tr>
                  <tr><td>Reference</td><td>{tournament.data.bankDetails.ref}</td></tr>
                </tbody>
              </Table>
              <p>Send proof of payment to: {tournament.data.bankDetails.popEmail}</p>
            </div>
          </Card.Body>
          <Card.Footer>
            <p>Thank you for your entry to {tournament.data.title}. You will
              receive the event schedule once entries have closed!</p>
          </Card.Footer>
        </Card>
        {displayFooter(tournament)}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {displayHeader(tournament)}
        <Card>
          <Card.Header>
            <h2>This Entry does not exist</h2>
          </Card.Header>
          <Card.Body>
            <Button onClick={() => { navigateTo('selectEvent') }}>Select Events</Button>
            <Button onClick={() => { navigateTo('enter') }}>Enter</Button>
          </Card.Body>
        </Card>
        {displayFooter(tournament)}
      </React.Fragment>
    )
  }
}


export default withRouter(PaymentProcessed);