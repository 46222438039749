import React from 'react';

const Landing = () => {

    return (
        <div className="w-100 h-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div>
                <div className="w-100">
                    <img alt="HomePage header" src="image/header.jpg" className="img-fluid" />
                </div>
                <h1 className="header-4">Canoe Polo Live</h1>
                <p>Welcome to canoe polo live, the online scoring system developed by Wedge Development for
      use in South African Canoe Polo tournaments</p>
                <br></br>
                <div className="w-100 text-center">
                    <a href="/entry" className="btn btn-primary">Entry Portal</a>
                </div>
            </div>
        </div>
    )
}

export default Landing;