import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import renderField from '../../utils/dynamic-form/formField';
import { fetchSingleTournmanet, fetchEntry, saveEntry } from '../../utils/api/index';
import PaynowButton from '../../utils/payfast/PaynowButton';

import { displayHeader, displayFooter } from '../../utils/components/tournamentComponents'
import EnteredTeamDiv from '../../utils/components/enteredTeam';

const payeeForm = [
    { "_id": "firstName", "type": "text", "title": "Payee First Name", "required": true, ref: { current: { value: null } } },
    { "_id": "lastName", "type": "text", "title": "Payee Last Name", "required": true, ref: { current: { value: null } } },
    { "_id": "email", "type": "email", "title": "Payee Email", "required": true, ref: { current: { value: null } } }
]


const Checkout = (props) => {
    const [tournament, updateTournament] = useState({ status: 0, data: {} });
    const [entry, updateEntry] = useState({ status: 0, data: {} });
    const [payeeDetails, setPayeeDetails] = useState({ status: 0, form: payeeForm })
    const history = useHistory();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        //test if redirect or direct
        console.log('...', 'in init');
        let tournament = null;
        let entry = null;
        let entryStatus = 0;

        if (props.location && props.location.state && props.location.state.tournament) {
            tournament = props.location.state.tournament;
        } else {
            //need to load from DB
            const result = await fetchSingleTournmanet(props.match.params.id);
            if (result.data && result.status === 200) {
                tournament = result.data;
            } else {
                console.log('fuck...')
            }
        }

        if (props.location && props.location.state && props.location.state.entry) {
            entry = props.location.state.entry;
        } else {
            //need to load from DB
            const result = await fetchEntry(props.match.params.entryId);
            if (result.data && result.status === 200) {
                entry = result.data;
                entryStatus = 1
            } else {
                entryStatus = -1
            }
        }
        updateTournament({ status: 1, data: tournament });
        updateEntry({ status: entryStatus, data: entry });
        setPayeeDetails({
            ...payeeDetails, status: 1, form: payeeForm.map(field => {
                return {
                    ...field,
                    ref: React.createRef()
                }
            })
        });
    }
    const loadPayout = () => {
        const payee = payeeDetails.form.reduce((payee, field) => {
            payee[field._id] = field.ref.current.value;
            return payee;
        }, {});
        const updatedEntry = {
            ...entry.data,
            paymentStatus: 'pending',
            payee
        }
        saveEntry(updatedEntry);
        updateEntry({...entry, data:updatedEntry})
        setPayeeDetails({ ...payeeDetails, status: 2, payee });
    }
    const displayPayeeForm = () => {
        switch (payeeDetails.status) {
            case 1: {
                return <React.Fragment>
                    <p>Please enter the details for the Payee</p>
                    <Form onSubmit={(e) => { e.preventDefault(); loadPayout(); }}>
                        <div className="row mb-4">
                            {
                                payeeDetails.form.map((field, i) => {
                                    return (
                                        <div className={`col${i <= 1 ? '-6' : ''}`}>
                                            {renderField(field, field.ref)}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Button type="submit">Load Checkout Link</Button>
                    </Form>
                </React.Fragment>
            }
            case 2: {
                return (
                    <React.Fragment>
                        <p>You will be redirected to PayFast to process the payment</p>
                        {
                            PaynowButton({
                                firstName: payeeDetails.payee.firstName,
                                lastName: payeeDetails.payee.lastName,
                                amount: entry.data.activeCategory.cost,
                                email: payeeDetails.payee.email,
                                id: entry.data.id,
                                teamName: entry.data.teamName,
                                tournament: tournament.data.title,
                                tournamentId: tournament.data.uid
                            })
                        }
                    </React.Fragment>
                )
            }
            default: return <React.Fragment></React.Fragment>
        }
    }

    const navigateTo = (location) => {
        switch (location) {
            case 'enter':
                history.push(`entry/${tournament.data.uid}`, { tournament: tournament.data });
                break;
            default:
                history.push(`entry`);
                break;
        }
    }

    if (tournament.status === 0) {
        return <Spinner animation="border" color="info"></Spinner>
    } else if (entry.status === 1) {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <Card.Title>Checkout</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <EnteredTeamDiv entry={entry} />
                        <h3>Payment Options</h3>
                        <p>Select if you would like to pay for the whole team or if you</p>
                        <h3>Costs</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                              {entry.data.validPlayers.map((player, i) => {
                                      return (
                                          <tr key={player.csaId}>
                                              <td>Entry for {player.name}</td>
                                              <td>{entry.data.activeCategory.cost}</td>
                                          </tr>
                                      );
                                  })}   
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th style={{ textAlign: "right" }}>TOTAL</th>
                                    <th>{entry.data.validPlayers.length*entry.data.activeCategory.cost}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        {displayPayeeForm()}
                    </Card.Footer>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <h2>This Entry does not exist</h2>
                    </Card.Header>
                    <Card.Body>
                        <Button onClick={() => { navigateTo('selectEvent') }}>Select Events</Button>
                        <Button onClick={() => { navigateTo('enter') }}>Enter</Button>
                    </Card.Body>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    }
}


export default withRouter(Checkout);