/**
 * 
 * @param {{
 *  data:{
 *      mainSponsorImages:[String]
 *  }
 * }} tournament 
 * @returns 
 */
export function displayHeader(tournament) {
    return (
        <div className="row">
            <div className="col-6">
                <h1>{tournament.data.title}</h1>
            </div>
            {
                tournament.data.mainSponsorImages.map((image, index) => {
                    return (<div key={`${index}_mainsponsorimages`} className="col text-center" style={{ height: '70px' }}>
                        <img className="img-fluid h-100 rounded" src={image} alt={`Sponsor ${index}`} />
                    </div>)
                })
            }
        </div>
    )
}
/**
 * 
 * @param {{
 *  data:{
 *      minorSponsorImages:[String]
 *  }
 * }} tournament 
 * @returns 
 */
export function displayFooter(tournament) {
    return (
        <div className="row">
            {
                tournament.data.minorSponsorImages.map((image, index) => {
                    return (<div key={`${index}_minorsponsorimages`} className="col text-center" style={{ height: '70px' }}>
                        <img className="img-fluid h-100 rounded" src={image} alt={`Sponsor ${index}`} />
                    </div>)
                })
            }
        </div>
    )
}