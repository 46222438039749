import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Spinner from '../../utils/components/Spinner';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { fetchSingleTournmanet, fetchEntry } from '../../utils/api/index';

import { displayHeader, displayFooter } from '../../utils/components/tournamentComponents'
import EnteredTeamDiv from '../../utils/components/enteredTeam';

const ProcessedEntry = (props) => {
    const [tournament, updateTournament] = useState({ status: 0, data: {} });
    const [entry, updateEntry] = useState({ status: 0, data: {} });
    const history = useHistory();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        //test if redirect or direct
        console.log('...', 'in init');
        let tournament = null;
        let entry = null;
        let entryStatus = 0;

        if (props.location && props.location.state && props.location.state.tournament) {
            tournament = props.location.state.tournament;
        } else {
            //need to load from DB
            const result = await fetchSingleTournmanet(props.match.params.id);
            if (result.data && result.status === 200) {
                tournament = result.data;
            } else {
                console.log('fuck...')
            }
        }

        if (props.location && props.location.state && props.location.state.entry) {
            entry = props.location.state.entry;
            entryStatus = 1;
        } else {
            //need to load from DB
            const result = await fetchEntry(props.match.params.entryId);
            console.log('result', result);
            if (result.data && result.status === 200) {
                entry = result.data;
                entryStatus = 1
            } else {
                entryStatus = -1
            }
        }
        updateTournament({ status: 1, data: tournament });
        updateEntry({ status: entryStatus, data: entry });
    }
    
    const navigateTo = (location) => {
        switch (location) {
            case 'enter':
                history.push(`entry/${tournament.data.uid}`, { tournament: tournament.data });
                break;
            default:
                history.push(`entry`);
                break;
        }
    }

    if (tournament.status === 0) {
        return <Spinner animation="border" color="info"></Spinner>
    } else if (entry.status === 1) {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <Card.Title>Entered Team Information</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <EnteredTeamDiv entry={entry} />
                    </Card.Body>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {displayHeader(tournament)}
                <Card>
                    <Card.Header>
                        <h2>This Entry does not exist</h2>
                    </Card.Header>
                    <Card.Body>
                        <Button onClick={() => { navigateTo('selectEvent') }}>Select Events</Button>
                        <Button onClick={() => { navigateTo('enter') }}>Enter</Button>
                    </Card.Body>
                </Card>
                {displayFooter(tournament)}
            </React.Fragment>
        )
    }
}


export default withRouter(ProcessedEntry);