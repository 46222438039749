import React, { useEffect, useState } from 'react';
import renderField from '../utils/dynamic-form/formField';
import { STATUS_UPDATE, STATUS_SEARCH } from '../utils/constants';
import { Modal, Button, Alert } from 'react-bootstrap';
import Spinner from '../utils/components/Spinner';
import create_UUID from '../utils/guid/generateGuid';


const addPlayerCsa = { "_id": "csaNumber", "type": "number", "title": "CSA Number (only SA players)", "required": false }
const addPlayerAge = { "_id": "age", "type": "number", "title": "Age", "required": true }
const addPlayerName = { "_id": "name", "type": "text", "title": "Name", "required": true }
const addPlayerGender = { "_id": "gender", "type": "text", "title": "Gender", "required": true }
const addPlayerPlayerNumber = { "_id": "playerNumber", "type": "number", "title": "Player Number", "required": true }


const PlayerModal = ({ show, player, status, onSubmit, onHide }) => {

  const playerCsaRef = React.useRef(null);
  const playerAgeRef = React.useRef(null);
  const playerNameRef = React.useRef(null);
  const playerGenderRef = React.useRef(null);
  const playerNumberRef = React.useRef(null);
  const [updateError, setUpdateError] = useState('');
  const [modalDisplay, setModalDisplay] = useState(<React.Fragment></React.Fragment>);

  const submitPlayer = () => {
    //fetch the player number and add it to the player object
    if(!player){
      player = {
        uid: create_UUID()
      };
    }
    
    player.number = playerNumberRef.current.value;
    player.csa = playerCsaRef.current.value;
    player.age = playerAgeRef.current.value;
    player.name = playerNameRef.current.value;
    player.gender = playerGenderRef.current.value;

    if (player.number === "") {
      setUpdateError("Please enter the player's number");
    } if (player.age === "") {
      setUpdateError("Please enter player's age");
    } if (player.name === "") {
      setUpdateError("Please enter the player's name");
    } if (player.gender === "") {
      setUpdateError("Please enter the player's gender");
    } else {
      onSubmit(player);
    }
  }

  useEffect(() => {
    switch (status) {
      case STATUS_UPDATE:
        setModalDisplay(
          <React.Fragment>
            {renderField({ ...addPlayerCsa, value: player.csa }, playerCsaRef)}
            {renderField({ ...addPlayerAge, value: player.age }, playerAgeRef)}
            {renderField({ ...addPlayerName, value: player.name }, playerNameRef)}
            {renderField({ ...addPlayerGender, value: player.gender }, playerGenderRef)}
            {renderField({ ...addPlayerPlayerNumber, value: player.number }, playerNumberRef)}
          </React.Fragment>
        );
        break;
      case STATUS_SEARCH:
        setModalDisplay(
          <React.Fragment>
            {renderField(addPlayerCsa, playerCsaRef)}
            {renderField(addPlayerAge, playerAgeRef)}
            {renderField(addPlayerName, playerNameRef)}
            {renderField(addPlayerGender, playerGenderRef)}
            {renderField(addPlayerPlayerNumber, playerNumberRef)}
          </React.Fragment>
        );
        break;
      default:
        setModalDisplay(<Spinner animation="border" color="info"></Spinner>)
    }
  }, [status]);

  return (
    <Modal animation={true} centered={true} show={show} onHide={() => onHide()} className='p-2'>
      <Modal.Title className='p-2'>Player Actions</Modal.Title>
      <Modal.Body>
        {
          modalDisplay
        }
        {updateError !== '' ? <Alert variant={'danger'}>{updateError}</Alert> : <></>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => submitPlayer()} type={'info'}>Submit</Button>
        <Button onClick={() => onHide()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );

}

export default PlayerModal;
