import React from 'react';
import Button from 'react-bootstrap/Button';

const crypto = require("crypto");

/**
 * 
 * @param {{
 *  firstName:String,
 *  lastName:String,
 *  email:String,
 *  id:String,
 *  amount:Number,
 *  teamName: String,
 *  tournament: String,
 *  tournamentId: String
 * }} entry 
 * @returns 
 */
const PayNowButton = (entry) => {
    const entryData = [];
    // Merchant details
    entryData["merchant_id"] = "11307371";
    entryData["merchant_key"] = "ho4culvgjf74n";
    entryData["return_url"] = `https://canoepololive.co.za/entry/${entry.tournamentId}/checkout/${entry.id}/processed`;
    entryData["cancel_url"] = `https://canoepololive.co.za/entry/${entry.tournamentId}/checkout/${entry.id}`;
    // Buyer details
    entryData["name_first"] = entry.firstName;
    entryData["name_last"] = entry.lastName;
    entryData["email_address"] = entry.email;
    // Transaction details
    entryData["m_payment_id"] = entry.id;
    entryData["amount"] = `${entry.amount.toFixed(2)}`;
    entryData["item_name"] = `CanoePoloLive Entry for team ${entry.teamName}`;
    entryData["item_description"] = `Entry on CanoePoloLive for team ${entry.teamName} for event ${entry.tournament}`;

    // Generate signature

    return (
        <form action={`https://www.payfast.co.za/eng/process`} method="post">
            {
                Object.keys(entryData).map(key => {
                    if (entryData.hasOwnProperty(key)) {
                        const value = entryData[key];
                        if (value !== "") {
                            return <input name={key} type="hidden" value={value.trim()} />;
                        }
                    }
                    return <React.Fragment></React.Fragment>
                })
            }
            <Button type="submit" variant="link">
                <img src="https://www.payfast.co.za/images/buttons/dark-large-paynow.png" width="174" height="59" alt="Pay" title="Pay Now with PayFast" />
            </Button>
        </form>
    )
}


const generateSignature = (data, passPhrase = null) => {
    // Create parameter string
    let pfOutput = "";
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            if (data[key] !== "") {
                pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, " + ")}&`
            }
        }
    }

    // Remove last ampersand
    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
        getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;
    }

    return crypto.createHash("md5").update(getString).digest("hex");
};

export default PayNowButton