import React from 'react';
import Form from 'react-bootstrap/Form';

const renderField = (field, reference, triggers) => {
    let { title, type, _id, required, placeholder, value } = field;

    switch (type) {
        case 'text':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control type="text" defaultValue={value || null} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'textarea':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control as="textarea" defaultValue={value || null} rows={field.rows || 3} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'email':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control type="email" defaultValue={value || null} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'number':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control type="number" defaultValue={value || null} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'date':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control type="date" defaultValue={value || null} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'time':
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control type="time" defaultValue={value || null} name={_id} placeholder={placeholder || ''} id={_id} ref={reference} required={required ? true : false} />
                </Form.Group>
            )
        case 'radio': {
            if (!field.options) {
                return <p>Invalid Model Field</p>
            }
            const onChangeHandler = (event, a) => {
                console.log(event, event.currentTarget);
                console.log(event.currentTarget.value);
                reference.current = {
                    value: event.currentTarget.value
                }
                if(triggers) {
                    triggers(event.currentTarget.value)
                }
            }
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    {field.options.map((option, i) => {
                        if (option.value && option.text && option.isDefault) {
                            return <Form.Check key={`${_id}_${i}`} type='radio' name={_id} label={option.text} value={option.value} defaultChecked={option.isDefault} onChange={onChangeHandler} />
                        } else if (option.value && option.text) {
                            return <Form.Check key={`${_id}_${i}`} type='radio' name={_id} label={option.text} value={option.value} onChange={onChangeHandler} />
                        }
                        return <Form.Check key={`${_id}_${i}`} type='radio' name={_id} label={option} value={option} onChange={onChangeHandler} />
                    })}

                </Form.Group>
            )
        }
        case 'checkbox': {
            if (!field.options) {
                return <p>Invalid Model Field</p>
            }
            return (
                <Form.Group key={_id}>
                    <Form.Label>{title} {field.required && <b className="text-danger">*</b>}</Form.Label>
                    {field.options.map((option, i) => {
                        if (option.value && option.text && option.isDefault) {
                            return <Form.Check key={`${_id}_${i}`} type='checkbox' name={_id} label={option.text} value={option.value} selected />
                        } else if (option.value && option.text) {
                            return <Form.Check key={`${_id}_${i}`} type='checkbox' name={_id} label={option.text} value={option.value} />
                        }
                        return <Form.Check key={`${_id}_${i}`} type='checkbox' name={_id} label={option} value={option} />
                    })}

                </Form.Group>
            )
        }
        case 'dropdown': {
            if (!field.options) {
                return <p>Invalid Model Field</p>
            }
            const onChangeHandler = (event, a) => {
                console.log(event, event.currentTarget);
                console.log(event.currentTarget.value);
                reference.current = {
                    value: event.currentTarget.value
                }
                if(triggers) {
                    triggers(event.currentTarget.value)
                }
            }
            return (
                <Form.Group key={_id} controlId={_id}>
                    <Form.Label>{title}{field.required && <b className="text-danger">*</b>}</Form.Label>
                    <Form.Control as="select" name={_id} required={required ? true : false} onChange={onChangeHandler}>
                        {
                            field.options.map((option, i) => {
                                return <option key={`${_id}_${i}`} value={option.value}>{option.text}</option>
                            })
                        }
                    </Form.Control>
                </Form.Group>
            )
        }
        default:
            return (
                <p>Invalid Model Field</p>
            );
    }
}

export default renderField;