/** 
 * A modal that takes the form data of pending team entry and allows the user to check all the data before confirming.
 * 
 */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Card } from 'react-bootstrap';
import EnteredTeamDiv from '../utils/components/enteredTeam';
import { displayFooter, displayHeader } from '../utils/components/tournamentComponents';

const EntryConfirmModal = (props) => {
  const [display, setDisplay] = useState(<React.Fragment></React.Fragment>);
  const tournament = props.tournament;

  const getTotalAmount = () => {
    let total = 0;
    props.entry.data.validPlayers.forEach((player) => {
      total += props.activeCategory.cost;
    });
    return total;
  }


  const displayPaymentTC = () => {
    if (props.tournament.data.isPreEntry) {
      return <React.Fragment>
        <p>
          By clicking confirm, you are agreeing that the team information and players are correct. Once you confirm, your team will be entered into the tournament.
          You will receive an email with the entry confirmation.
        </p>
      </React.Fragment>
    } else {
      return <React.Fragment>
        <p>
          You will be directed to a confirmation page with the payment details. The amount due is reflected below.
        </p>
        <p>
          Proof of payment must be sent to <a href={`mailto:${tournament.data.organiserEmail}`} target="_blank" rel="noreferrer">{tournament.data.organiserEmail}</a>
          to confirm your entry.
        </p>
        <i>Note: Your entry will only be valid on proof of payment received</i>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Account Name</td>
              <td>{tournament.data.bankDetails.accountName}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{tournament.data.bankDetails.accNum}</td>
            </tr>
            <tr>
              <td>Bank</td>
              <td>{tournament.data.bankDetails.bank}</td>
            </tr>
            <tr>
              <td>Branch Code</td>
              <td>{tournament.data.bankDetails.branch}</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>{tournament.data.bankDetails.ref}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td><b>R {getTotalAmount()}</b></td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    }
  }

  useEffect(() => {
    const entry = props.entry;
    const tournament = props.tournament;
    if (entry.data) {
      console.log('entry', entry.data)
      setDisplay(<React.Fragment>
        {displayHeader(tournament)}
        <Card>
          <Card.Header>
            <Card.Title>Confirm <u>{entry.data.teamName}</u> for the <b>{tournament.data.title}</b></Card.Title>
          </Card.Header>
          <Card.Body>
            <EnteredTeamDiv entry={entry} />
          </Card.Body>
          <Card.Footer>
            {displayPaymentTC()}
          </Card.Footer>
        </Card>
        {displayFooter(tournament)}
      </React.Fragment>);
    }
  }, [props.entry]);


  return (
    <Modal show={props.show} onHide={props.onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {display}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Make Changes
        </Button>
        <Button variant="success" className={"mx-2"} onClick={() => { props.onSubmit() }}>
          Confirm Entry
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EntryConfirmModal;
