import { parse, isAfter, fromUnixTime } from 'date-fns';
import testAgeValid from './testAgeValid';
import testGenderValid from './testGenderValid';

/**
   * 
   * @param {*} member 
   * @param {{minAge:number,maxAge:number}} activeCategory 
   * @returns {{
*  firstName:String,
*  lastName:String,
*  dateofBirth:Date,
*  expiryDate:Date,
*  age:number,
*  licenseValidity:Boolean,
*  ageValidity:Boolean,
*  gender:String
* }}
*/
export default function testMemberValid (member, activeCategory, tournament) {
  const expiryDate = parse(member.expiryDate, 'yyyy-MM-dd', new Date())
  const tournamentDate = fromUnixTime(tournament.startDate['_seconds']);
  const licenseValidity = isAfter(expiryDate, tournamentDate);
  const {ageValidity, age, dateofBirth} = testAgeValid(member, activeCategory);
  const genderValid = testGenderValid(activeCategory, member);
  const licenseTypeValid = testLicenseypeValid(tournament.minLicenseRequired, member)

  // quick data improvement to set the license type name to NONE if null
  if(member.licenceTypeName === null) {
    member.licenceTypeName = 'NONE'
  } else {
    member.licenceTypeName = member.licenceTypeName.toUpperCase()
  }

  return {
    ...member,
    name: member.name || `${member.firstName} ${member.lastName}`,
    readableExpiryDate: member.expiryDate,
    dateofBirth,
    expiryDate,
    age,
    licenseValidity,
    ageValidity,
    genderValid,
    licenseTypeValid
  }
}

// TODO add these to the retool updates
const MIN_NO_LICENSE = "NO LICENSE REQUIRED"
const MIN_SOCIAL_LICENSE = "AT LEAST SOCIAL LICENSE"
const MIN_COMPETITIVE_LICENSE = "AT LEAST RACING LICENSE"
/** function that tests the category minimum license vs the players license */
function testLicenseypeValid (minLicenseRequired, member) {
  switch (minLicenseRequired) {
    case MIN_NO_LICENSE:
      return true;
    case MIN_SOCIAL_LICENSE:
      if(member.licenceTypeName !== null) {
        console.log('Valid license for social, has license: ', member.licenceTypeName)
        return true;
      }
      return false;
    case MIN_COMPETITIVE_LICENSE:
      if(member.licenceTypeName !== null && member.licenceTypeName.toLowerCase() !== "social member") {
        console.log('Valid license for racing, has license: ', member.licenceTypeName)
        return true;
      }
      return false;
    default:
      return true;
  }
}
